export default {
  "Total pledge": "Total Pledge",
  "Current pledge": "Current Pledge",
  Reward: "Reward",
  "Total Reward": "Total Destruction",
  "Invite Friends": "Invite Friends",
  Invite: "Invite",
  Personal: "Personal",
  Introduce: "Introduce",
  Detail: "Detail",
  Notice: "Notice",
  "Platform pledge amount": "Platform Pledge Amount",
  "Clown Fish": "Clown Fish",
  Harvest: "Harvest",
  "New user only": "New user only",
  Pledge: "Pledge",
  Fishing: "Fishing",
  "Day earn rate": "Day earn rate",
  Max: "Max",
  "Referral link": "Referral link",
  Copy: "Copy",
  "Contact us": "Contact us",
  "My invitation code": "My invitation code",
  "Using Decentralized Finance(DEFI), user funds are aggregated through smart contracts, and the unique FFT fund protection mechanism can effectively avoid the selection of income for projects with the highest mining yield using unique technology. Poor projects, the original pull-out machine gun pool play method, according to historical backtesting, can achieve an annual income of 482%. We open source the project for everyone to use (the core code is kept secret), and pass the review of a professional third-party code review organization to ensure the safety of all user funds.":
    "Using Decentralized Finance(DEFI), user funds are aggregated through smart contracts, and the unique FFT fund protection mechanism can effectively avoid the selection of income for projects with the highest mining yield using unique technology. Poor projects, the original pull-out Defi play method, according to historical backtesting, can achieve an annual income of 482%. We open source the project for everyone to use (the core code is kept secret), and pass the review of a professional third-party code review organization to ensure the safety of all user funds.",
  "Enter amount": "Enter amount",
  Submit: "Submit",
  "Clown fish  is only for new users, you can invite your friends to join to receive benefits.You will have a total of 1 pledge opportunity within 24 hours, invite friends to unlock more pledge amount":
    "Clown fish  is only for new users, you can invite your friends to join to receive benefits.You will have a total of 1 pledge opportunity within 24 hours, invite friends to unlock more pledge amount",
  Connect: "Link wallet",
  Address: "Address",
  Level: "Level",
  Time: "Time",
  "Fish Pond Type": "Fish Pond Type",
  Earn: "Earn",
  "Withdrawal Amount": "Withdrawal Amount",
  Team: "Team",
  Amount: "Amount",
  Rate: "Rate",
  "Please enter the amount!": "Please enter the amount!",
  "Pledge amount": "Pledge amount:",
  "Please enter the USDT pledge amount": "Please enter the USDT pledge amount",
  "Wallet calling...": "Wallet calling...",
  "Operation failed!": "Operation failed!",
  "Operation successful": "Operation successful!",
  "Best DEFI vault": "FD",
  Copied: "Copied!",
  "Cannot copy!": "Cannot copy!",
  "Click to view": "Click to view",
  Prompt: "Prompt",
  "Return Notice":
    "The failed withdrawal amount will be refunded to the account within 24 hours, please ensure that there is enough gas fee",
  "Referee Reward": "Referee Reward",
  "Withdrawal Income": "Withdrawal",
  "Earn Type": "Earn Type",
  Status: "Status",
  "User Address": "User Address",
  "Subordinate Address": "Subordinate Address",
  "First Generation": "First Generation",
  "Second Generation": "Second Generation",
  "Third Generation": "Third Generation",
  "Not extracted": "Not extracted",
  Extracting: "Extracting",
  "Extraction Complete": "Extraction Complete",
  "Extraction Failed": "Extraction Failed",
  "Draw Time": "Draw Time",
  "Draw Hash": "Draw Hash",
  OK: "OK",
  Cancel: "Cancel",
  Approve: "Approve",
  "Available direct referral": "Available direct referral",
  "Draw direct referral rewards": "Draw direct referral rewards",
  "Available team rewards": "Available team rewards",
  "Draw team rewards": "Draw team rewards",
  "Promotion income": "Promotion income",
  "Team income": "Team income",
  "User level": "User level",
  "Team pledge amount": "Team pledge amount",
  "Switch Chain": "Switch Chain",
  "Switch Language": "Switch Language",
  Home: "Home",
  "Machine Gun Pool": "Defi",
  My: "My",
  "No data": "No data",
  "The total pledge of the platform": "Total dividend of holding coins",
  "Total address": "Total address",
  "Minimum profit ratio": "Minimum profit ratio",
  "FD related introduction": "FD related introduction",
  "Scan the whole network": "Scan the whole network",
  "Real-time data analysis to execute the best strategy":
    "Real-time monitoring of on-chain data analysis, automatic implementation of various quantitative arbitrage strategies such as sandwiches, flash loans, dex-cex, etc.",
  "Strategy mix": "Strategy mix",
  "Automatic switching of policies to ensure high yield":
    "In addition to creating high returns in the digital currency trading market, Fuding Fund uses a combination of short, medium and long-term investment strategies with high leverage and an automatic trading system to create high returns. The currency economy investment model, through the issuance of digital currency and dual currency investment operations, to obtain high-efficiency investment feedback.",
  "Financial freedom": "Financial freedom",
  "Deposit and withdraw anytime, flexible funds":
    "Deposit and withdraw anytime, flexible funds",
  "Latest transaction": "Latest transaction",
  "To view": "To view",
  "Security audit": "Security audit",
  Partner: "Partner",
  "Contact Customer Service": "Contact Customer Service",
  "The strongest DEFI machine gun pool": "Quantification, asset management, Defi",
  "FD owns the world's top ten funds' far-sighted financial plan and invites you to share financial returns!":
    "FD owns the world's top ten funds' far-sighted financial plan and invites you to share financial returns!",
  "Beginner's Guide": "Beginner's Guide",
  Welcome: "Welcome",
  "FD enjoy high yield": "FD enjoy high yield",
  Overview: "Overview",
  "Pledge Record": "Pledge Record",
  "Current Reward": "Current Reward",
  "Please link the wallet first": "Please link the wallet first",
  "Invitation link": "Invitation link",
  "Total number of invites": "Total number of invites",
  "Invitation total revenue": "Invitation total revenue",
  "Team Rewards": "Team Rewards",
  "Harvest Reward": "Harvest Reward",
  "Level description": "Level description",
  Introduction: "Introduction",
  "Copy the link and share it with your friends, you will get higher pledge registration and enjoy higher investment income!":
    "Copy the link and share it with your friends, you will get higher pledge registration and enjoy higher investment income!",
  "Company Profile": "Company Profile",
  "We are a business entity of Fortune Group...":
    "We are a world renowned Fidelity fund strategy executive and a fund company that expands investment in digital assets. In recent years, with the popularity of digital currency around the world, it has been widely accepted and used for various sports and virtual goods transactions on the Internet. Relying on its professional advantages in financial products and overseas Huaihuai region, the group established Fuding Company. Through the operation of ultra-efficient and systematic investment strategies, we can create effective and stable high annualized returns. Through global investment crowdfunding, the trading base can be expanded, and with the steady growth of funds, it can bring a win-win situation to the investing public and companies.",
  "We have the world's top 30 investment strategy teams...":
    "We have the world's top 30 investment strategy teams, enjoying high investment returns in commodity futures, index futures and foreign exchange. Especially in the digital asset investment strategy in the past two years, through short-term, medium-term and long-term strategic planning, the average annualized rate has reached more than 300%.",
  "FishDefi has a total of three chains: ETHTRXBSC...":
    "FishDefi has a total of three chains: ETHTRXBSC for you to choose; 10U pledge is only for new users, and the balance in the wallet must have 110U to make 10U pledge: In addition, ensure that each arrowhead in your wallet has enough channel fees (TRX). You will have a pledge opportunity to activate your account within 24 hours. The pledged amount and profit will be automatically returned to your wallet account by the contract after 24 hours. You can invite your friends to join to get a higher level , unlock more pledge amount·",
  "After the first pledge is over...":
    "After the first pledge is over: you will become our VIP customer: VIP customers can enjoy 7*24 hours of withdrawal of principal + profit: the arrival time depends on the blocking situation on the defense: VIP customers need to share the link to Unlock more pledge amount, each time you share a friend: you will increase the pledge amount by 500U, and your daily income will also increase, and if you reach 10 people, you will get unlimited pledge amount: if the wallet you share is not pledged: the system will automatically After identification, your VIP level will be lowered to the corresponding amount.",
  "Please refer to the table below：": "Please refer to the table below：",
  "Pledge quantity": "Pledge quantity",
  "Profit % (days)": "Profit % (days)",
  "Number of shared tokens": "Number of shared tokens",
  Unlimited: "Unlimited",
  "Refer to the event announcement": "Refer to the event announcement",
  "Not fixed every week": "Not fixed every week",
  VIP11:
    "Recommend an effective wallet. \"Direct promotion of 100U pledged by lower level is an effective promotion.\" Your pledge principal amount and income will increase accordingly. Conversely, direct promotion of lower level will reduce the pledge amount and daily income percentage of higher level after principal withdrawal. The maximum income is 1.1% daily. The pledge amount increases with the number of direct sales. For each additional effective wallet address, the pledge amount increases by 500U",
  "Note: If the withdrawal on DAPP does...":
    "Note: If the withdrawal on DAPP does not arrive, please wait patiently, the contract will automatically return the principal and profit to your wallet after 24 hours. During this time, you will not be able to pledge for the second time: you must wait for the coins to return to the wallet: you can re-stake the next time!",
  "Platform Introduction": "Platform Introduction",
  "Using Decentralized Finance (DEFI)...":
    "Using Decentralized Finance (DEFI), user funds are aggregated through smart contracts, and the unique FFT funds protection mechanism can effectively avoid the selection of income from projects with the highest exact yield using unique technologies. The poor project, the original method of pulling out the defi, based on the historical retrospective test, the annual income can reach 482%. We open source the project for everyone to use (the core code is kept secret), and through professional third-party code reviewers, we ensure the safety of all user funds.",
  "Related question": "Related question",
  "1： There must be a certain amount of gas in the wallet as a service charge on the chain when it is pledged. Different chains need to consume TRX, and NbN.":
    "1： There must be a certain amount of gas in the wallet as a service charge on the chain when it is pledged. Different chains need to consume TRX, and NbN.",
  "2： U on different chains has different links to DAPP. (you can apply for customer service or referrals to give you the link of the corresponding chain, otherwise your wallet will not be linked to DAPP software)":
    "2： U on different chains has different links to DAPP. (you can apply for customer service or referrals to give you the link of the corresponding chain, otherwise your wallet will not be linked to DAPP software)",
  '3： After entering DAPP, please pay attention to the bar box in the upper left corner of the software and confirm whether it has been linked. (if the data is not displayed, please click the word "link" to ensure that the link is successful and the platform data is clearly displayed.).':
    '3： After entering DAPP, please pay attention to the bar box in the upper left corner of the software and confirm whether it has been linked. (if the data is not displayed, please click the word "link" to ensure that the link is successful and the platform data is clearly displayed.).',
  "4： Click the machine gun pool option under the software to jump to the pledge interface. If you want to open pledge financing, please click authorization, and then enter the password, so that the authorization is successful.":
    "4： Click the Defi option under the software to jump to the pledge interface. If you want to open pledge financing, please click authorization, and then enter the password, so that the authorization is successful.",
  "5： Congratulations, you have become a VIP customer of Fuding wealth management, with an initial limit of 100u":
    "5： Congratulations, you have become a VIP customer of Fuding wealth management, with an initial limit of 100u",
  "6： For the promotion of quota and grade, please consult your promoter or customer service! Thank you for your support!":
    "6： For the promotion of quota and grade, please consult your promoter or customer service! Thank you for your support!",
  "intro-p1": "With decentralized financing (DEFI), user funds are aggregated through smart contracts. The unique FFT fund protection mechanism can effectively avoid the income of projects that use unique technologies to obtain the highest accurate income. For poverty projects, the original method of pulling out the defi can achieve an annual income of 482% according to historical retrospective tests.",
  "p1": "I: The money package must have a certain GAS as the pledge, and the service charge will be charged on the chain. Different chains need to consume TRX, BNB and ETH.",
  "p2": "II: The link to DAPP is different for U on different chains. (You can apply for customer service or a referral to give you the link to the corresponding chain, otherwise your wallet will not link to DAPP software)",
  "p3": "III: After entering DAPP, please observe the bar and box on the upper left corner of the software and confirm whether it has been linked. (If the data is not displayed, please click the word\" link \", or click the upper right corner to refresh the page to ensure that it has been successfully linked and the platform data is clearly displayed.)",
  "p4": "IV: Click the (Defi) option under the software to jump to the pledge interface. If you want to open the pledge financing, click on authorization, and then enter the password until the authorization is successful.",
  "p5": "V: Congratulations, you have become a VIP customer of Fuding Financial, with an initial limit of 100U",
  "p6": "VI: Please consult your recommender or customer service to improve the quota and level!",
  "p7": "Thank you for your support!",
  "Team pledge": "Team pledge",
  "Recommendation reward": "Recommendation reward",
  "Pledge range": "Pledge range",
  "Amount below minimum": "Amount below minimum",
  "Direct Push Pledge": "Direct Push Pledge",
  "Number Of Direct Referral": "Number Of Direct Referral",
  "Number Of Indirect Referral": "Number Of Indirect Referral",
  "Indirect Push Pledge": "Indirect Push Pledge",
  "Pledge Amount": "Pledge Amount",
  "Exceeded the limit": "Exceeded the limit!",
  "Subordinate": "Subordinate",
  "Withdrawal reward": "Withdrawal reward",
  "1s": "1s",
  "1-day interest": "Regular 7-day",
  "3-day interest": "Regular 15-days",
  "7-day interest": "Regular 30-days",
  "60-day interest": "Regular 60-days",
  "120-day interest": "Regular 120-days",
  "General pledge": "General pledge",
  "Regular pledge": "Regular pledge",
  "Number of subordinates": "Number of subordinates",
  "Pledged": "Pledged",
  "Completed": "Completed",
  "Pledge Time": "Pledge Time",
  "Current pool pledge": "Current pool pledge",
  "Cannot operate": "Cannot operate!",
  "Cumulative reward": "Cumulative reward",
  "Accumulated pledge": "Accumulated pledge",
  "Buy": "Buy",
  "Sell": "Sell",
  "Mining": "Mining",
  "Coming soon": "Coming soon",
  "Latest Attack": "Sandwich arbitrage",
  "Bind": "Bind",
  "Bind to superior": "Bind to superior",
  "Binding successful": "Binding successful",
  "Please enter the address of the superior": "Please enter the address of the superior",

  "Team Reward": "Team Reward",
  "My Team": "My Team",
  "My Taxes": "My Taxes",
  "My Superior": "My Superior",
  "Announcement": "Announcement",
  "Customer Service": "Customer Service",
  "Airdrop": "Airdrop",
  "FDS Wallet": "FDS Wallet",
  "Tax Details": "Tax Details",
  "FDS Details": "FDS Details",
  "Total Assets": "Total Assets",
  "Withdrawal": "Withdrawal",
  "Recharge": "Recharge",
  "*The data shows the last 7 days": "*The data shows the last 7 days",
  "Miming income": "Daily income",
  "Individual accumulated tax": "Individual accumulated tax",
  "Single cumulative income": "Single cumulative income",
  "Tax rate": "Tax rate",
  "Tax exemption": "Tax exemption",
  "View Tax Statement": "View Tax Statement",
  "Records": "Records",
  "Subscription": "Subscription",
  "Superior's address": "Superior's address",
  "Superior grade": "Superior grade",
  "Team acceleration": "Team acceleration",
  "Friends invited": "Friends invited",
  "Online friends": "Online friends",
  "Current online friends": "Current online friends",
  "Acceleration calculation formula": "Acceleration calculation formula",
  "Start Mining": "Start Mining",
  "Activate once every 24 hours to continue to earn benefits": "Activate once every 24 hours to continue to earn benefits",
  "Base Speed": "Base Speed",
  "Team Speed": "Team Speed",
  "Real-time earnings": "Real-time earnings",
  "Current total speed": "Current total speed",
  "Scan to add Twitter customer service, help you solve problems!": "Scan to add Twitter customer service, help you solve problems!",
  "Scan to add Telegram customer service, help you solve problems!": "Scan to add Telegram customer service, help you solve problems!",
  "Current Base Speed": "Current Base Speed",
  "The total issuance of FDS coins is 1 billion, and 750 million are all used for mining and production, and production will be reduced based on the number of FDS mined. When the FDS output reaches 300 million, the main currency listing plan will be launched!": "The total issuance of FDS coins is 10 billion, and 7.5 billion are all used for mining and production, and production will be reduced based on the number of FDS mined. When the FDS output reaches 300 million, the main currency listing plan will be launched!",
  "Billion": "Billion",
  "Airdrop Record": "Airdrop Record",
  "Taxex": "Taxex",
  "Extract": "Extract",
  "Map":"Map",
  "National Airdrop": "National Airdrop",
  "VIP Airdrop": "VIP Airdrop",
  "Online": "Online",
  "Draw Records":  "Draw Records",
  "Not open yet": "Not open yet",
  "Mining income": "Mining income",
  "Taxes": "Taxes",
  "Loading": "Loading",
  "Team Description": "Team Description",
  "Team Level": "Team Level",
  "Recharge Amount": "Recharge Amount",
  "Please enter the recharge amount": "Please enter the recharge amount",
  "Please enter the withdraw amount": "Please enter the withdraw amount",
  "Withdrawal record": "Withdrawal record",
  "Approve FDS": "Approve FDS",
  "Tip: Please check if you need enough fuel?": "Tip: Please check if you need enough fuel?",
  "Peer award": "Peer award",
  "Treasure": "Treasure",
  "Low Risk": "Low Risk",
  "High Income": "High Income",
  "My Reward": "My Reward",
  "State": "State",
  "Received amount": "Received amount",
  "Minimum withdrawal amount:": "Minimum withdrawal amount:",
  "Withdrawal service fee deducted:": "Withdrawal service fee deducted:",
  "My FD": "My FD",
  "Flop record": "Flop record",
  "Today's remaining flops": "Today's remaining flops",
  "Currently owns": "Currently owns",
  "Accept": "Accept",
  "Knew": "Knew",
  "OPEN": "OPEN",
  "Consumption once flipped": "Consumption once flipped",
  "Consume": "Consume",
  "Win the lottery": "Win the lottery",
  "Didn't win": "Didn't win",
  "Node": "Node",
  "Obtain": "Obtain",
  "Has arrived": "Has arrived",
  "Withdrawing": "Withdrawing",
  "FDS account": "FDS account",
  "FD account": "FD account",
  "game-entry-1": "The game is divided into three modes: FDS, FD, and USDT. The gameplay in each mode is the same, except that the payment currency is different, and the reward currency is also different",
  "game-entry-2": "Users have a certain probability of receiving rewards ranging from 6 to 10 times, and the rewards can be timely credited to the balance for withdrawal",
  "Flip Rules": "Flop Rules",
  "game-low-1": "Each user has 10 opportunities per day, each time consuming a certain amount of FDS tokens",
  "game-low-2": "Users will reshuffle their cards after every flip",
  "game-low-3": "The low-risk model has about 50% chance of winning the lottery, and the amount of winning the lottery will be relatively small, which is suitable for risk aversion users to participate",
  "game-high-1": "Each user has 10 opportunities per day, each time consuming a certain amount of FDS tokens",
  "game-high-2": "Users will reshuffle their cards after every flip",
  "game-high-3": "The high-yield model has a winning chance of about 20%, and the winning amount has increased compared to the low-risk model, making it more suitable for users with risk preferences to participate",
  "Game": "Game",
  "Flip consumption": "Flip consumption",
  "FD Details": "FD Details",
  "FD Wallet": "FD Wallet",
  "Approve FD": "Approve FD",
  "Number of subscribers": "Number of subscribers",
  "Phase 1": "Phase 1",
  "Phase 2": "Phase 2",
  "Phase 3": "Phase 3",
  "In Progress": "In Progress",
  "Not Open": "Not Open",
  "Starting block height": "Starting block height",
  "End block height": "End block height",
  "Subscription Price": "Subscription Price",
  "Subscription Amount": "Subscription Amount",
  "Obtaining Tokens": "Obtaining Tokens",
  "Node recruitment": "Node recruitment",
  "Node desc": "Genesis node users are the core participants and architects of the entire ecological platform of FD, with the right to jointly participate in the platform's later voting and decision-making, and enjoy all the platform's incentives and profits, including later FD main network incentives, wallet profit dividends, and so on",
  "Ecological Reward": "Ecological Reward",
  "Start Time": "Start Time",
  "End Time": "End Time",
  "Node Rights": "Node Rights",
  "Total Award": "Total Award",
  "Remaining Release": "Remaining Release",
  "Withdrawable Amount": "Withdrawable Amount",
  "Obtain an FD token reward equivalent to 5000USDT (locked for 90 days, and then released monthly for 12 months to complete)": "Obtain an FD token reward equivalent to (locked for 90 days, and then released monthly for 12 months to complete)",
  "Recruitment Process": "Recruitment Process",
  "Share 25% of exchange transaction fees": "Share 25% of exchange transaction fees",
  "25% of the profit withdrawal fee on the sharing platform": "25% of the profit withdrawal fee on the sharing platform",
  "Identity of platform co-founder": "Identity of platform co-founder",
  "Participate in the platform's later voting decision - making power": "Participate in the platform's later voting decision - making power",
  "Token Collection": "Token Collection",
  "Record": "Record",
  "Date": "Date",
  "Quantity": "Quantity",
  "Participate": "Participate",
  "Stay tuned": "Stay tuned",
  "Redemption principal": "Redemption principal",
  "Gain profit": "Gain profit",
  "Deduction fee": "Deduction fee",
  "Fee": "Fee",
  "Redemption": "Redemption",
  "Pending approval": "Pending approval",
  "Pending transfer": "Pending transfer",
  "Transferring": "Transferring",
  "Successful": "Successful",
  "Failed": "Failed",
  "Ended": "Ended",
  "Subscription failed!": "Subscription failed!",
  "Subscription successful!": "Subscription successful!",
  "Benefits can be received": "Benefits can be received",
  "No.": "No.",
  "Subscribed": "Subscribed",
  "IDO Description": "IDO Description",
  "Token Release": "Token Release",
  "Extract Quantity": "Extract Quantity",
  "The user successfully released 16% of the tokens after participating in IDO subscription, and the remaining 84% of the tokens were released at a monthly rate of 14% starting from token trading. The release was completed within 6 months": "The user successfully released 16% of the tokens after participating in IDO subscription, and the remaining 84% of the tokens were released at a monthly rate of 14% starting from token trading. The release was completed within 6 months",
  "Each address can only be subscribed once": "Each address can only be subscribed once",
  "The subscribed tokens are released on the token release page, and if there is a limit available, it can be withdrawn": "The subscribed tokens are released on the token release page, and if there is a limit available, it can be withdrawn",
  "Contact Official Application": "Contact Official Application",
  "Quotes": "Quotes",
  "Platform": "Platform",
  "Official site": "Official site",
  "Swap": "Swap",
  "Service": "Service",
  "Draw amount can not be 0": "Draw amount can not be 0",
  "Application": "Application",
  "Released": "Released",
  "Node users who have opened subscription permission": "Node users who have opened subscription permissions and fail to complete the contract within three days will be automatically disqualified and cannot subscribe again",
  "Subscription requires maintaining an account of 10000u": "Subscription requires maintaining an account of 10000u",
  "The node function will be closed when the node quota reaches": "The node function will be closed when the node quota reaches or the subscription cycle is completed",
  "Blind Box": "Blind Box",
  "Lottery Results": "Lottery Results",
  "Participation Record": "Participation Record",
  "USDT account": "USDT account",
  "USDT Details": "USDT Details",
  "USDT Wallet": "USDT Wallet",
  "Lucky Award": "Lucky Award",
  "The first prize": "The first prize", 
  "Second prize": "Second prize",
  "Third prize": "Third prize",
  "Participation Award": "Participation Award",
  "Random": "Random",
  "Reward ranking": "Reward ranking",
  "Lottery rules": "Lottery rules",
  "Select a token": "Select a token",
  "Withdrawal service fee": "Withdrawal service fee",
  "Participation2": "Participate",
  "Current prize pool": "Current prize pool",
  "If there are significant adverse conditions in the price before confirming the price, your transaction will be withdrawn": "If there are significant adverse conditions in the price before confirming the price, your transaction will be withdrawn",
  "AMM: The difference between market prices and estimated prices is caused by the size of the transaction.": "AMM: The difference between market prices and estimated prices is caused by the size of the transaction.",
  "Slippage Tolerance": "Slippage Tolerance",
  "Minimum acquisition amount": "Minimum acquisition amount",
  "Price impact": "Price impact",
  "Swap fee": "Swap fee",
  "Price": "Price",
  "Set Up": "Set Up",
  "Recent Transactions": "Recent Transactions",
  "Default Transaction Speed": "Default Transaction Speed",
  "swap-help1": "Transaction fee: Purchase 3% of the received quantity of FD tokens and sell 5% of the sold quantity of FD tokens",
  "Balance": "Balance",
  "Maximum": "Maximum",
  "Minimum recharge amount:": "Minimum recharge amount:",
  "Instant": "Instant",
  "Standard": "Standard",
  "Fast": "Fast",
  "Default": "Default",
  "Mapping": "Mapping",
  "1. Equivalent FD of 1U market price participating in blind box once": "1. One blind box with FD equivalent to market price of 1U",
  "2. 100% of the user's participation amount will be credited to the prize pool, with an initial bottom pool of 1WFD": "2. 100% of the user's participation amount will be deposited into the prize pool, with an initial bottom pool of 10000U equivalent FD quantity",
  "3. The daily first prize amount accounts for 10% of the total prize pool, the second prize amount accounts for 8% of the total prize pool, equivalent to FD, the third prize amount accounts for 5% of the total prize pool, and the number of awards is 1. Once the awards are withdrawn on that day, they will not be issued again, and the award amount will be reset at midnight every day": "3. The daily first prize amount accounts for 5% of the total prize pool, the second prize amount accounts for 3% of the total prize pool, which is equivalent to USDT, the third prize amount accounts for 2% of the total prize pool, and the number of awards is 1. Three awards must be issued every day, and once the awards are drawn, they will not be issued again. The award amount is reset at midnight every day",
  "4. The Lucky Award has an indefinite daily quantity and an indefinite bonus of 300FD, with a single cap": "4. The lucky prize bonus is fd, with an indefinite quantity and bonus per day",
  "5. Participating users will win 100% of the prize and be synchronized on the announcement board of the prize pool": "5. 100% of participating users win, synchronized on the prize pool announcement board",
  "Treasure Rewards": "Treasure Rewards",
  "Exchange": "Exchange",
  "Approve Failed": "Approve Failed",
  "Approve Success": "Approve Success",
  "Transaction": "Transaction",
  "No available balance": "No available balance",
  "Confirm Swap": "Confirm Swap",
  "To Be Mapped": "To Be Mapped",
  "Mapped Quantity": "Transfer to mapping pool",
  "All": "All",
  "Total Return": "Total Return",
  "To Be Released": "To Be Released",
  "Mapping record": "Mapping record",
  "Release record": "Release record",
  "Income": "Income",
  "Sure": "Sure",
  "Swap2": "Swap",
  "Freed": "Freed",
  "Output is estimated. You will receive at least {coin} or the transaction will revert.": "Output is estimated. You will receive at least {coin} or the transaction will revert.",
  "24H": "24H",
  "1W": "1W",
  "1M": "1M",
  "1Y": "1Y",
  "Transfer out": "Transfer out",
  "Transfer Amount": "Transfer Amount",
  "FDS mapping": "FDS mapping",
  "FDS mapping transfer": "FDS mapping transfer",
  "Mapping team rewards": "Mapping team rewards",
  "Node dividend": "Node dividend",
  "Coin holding dividends": "Coin holding dividends",
  "Team dividend": "Team dividend",
  "Transfer to airdrop": "Transfer to airdrop",
  "FD mapping rewards": "FD mapping rewards",
  "FD mapping release": "FD mapping release",
  "No available balance!": "No available balance!",
  "Please customize slippage!": "Please customize slippage!",
  "Please input amount!": "Please input amount!",
  "Below the minimum withdrawal amount!": "Below the minimum withdrawal amount!",
  "Insufficient balance": "Insufficient balance",
  "Bonus": "Bonus",
  "Confirm": "Confirm",
  "Frequency": "Frequency",
  "Congratulations on winning": "Congratulations on winning the",
  "Blind box consumption": "Blind box consumption",
  "Blind box reward": "Blind box reward",
  "Blind box dividend": "Blind box dividend",
  "time": "time",
  "The maximum value has been reached": "The maximum value has been reached",
  "ABonus": "A bonus",
  "USDT Treasure": "USDT Treasure",
  "FDS Treasure": "FDS Treasure",
  "FD Treasure": "FD Treasure",
  "game-page-rule-1": "Each user has 10 opportunities per day, each time consuming a fixed amount of tokens",
  "game-page-rule-2": "After each flip, the user will shuffle the cards again",
  "game-page-rule-3": "Users who flip can receive 6-10 times the corresponding reward"
};
