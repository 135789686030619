export default {
  "Total pledge": "總質押",
  "Current pledge": "當前質押",
  Reward: "獎勵",
  "Total Reward": "總銷毀",
  "Invite Friends": "邀請朋友",
  Invite: "邀請",
  Personal: "個人",
  Introduce: "介紹",
  Detail: "詳細",
  Notice: "公告",
  "Platform pledge amount": "平台質押金額",
  "Clown Fish": "小醜魚",
  Harvest: "收獲",
  "New user only": "僅限新用戶",
  Pledge: "質押",
  Fishing: "質押",
  "Day earn rate": "日回報率",
  Max: "最大",
  "Referral link": "邀請鏈接",
  Copy: "複制",
  "Contact us": "聯絡我們",
  "My invitation code": "我的邀請碼",
  "Using Decentralized Finance(DEFI), user funds are aggregated through smart contracts, and the unique FFT fund protection mechanism can effectively avoid the selection of income for projects with the highest mining yield using unique technology. Poor projects, the original pull-out machine gun pool play method, according to historical backtesting, can achieve an annual income of 482%. We open source the project for everyone to use (the core code is kept secret), and pass the review of a professional third-party code review organization to ensure the safety of all user funds.":
    "使用分散金融（DEFI），用戶資金通過智能合同聚合，獨特的FFT資金保護機制可以有效避免選擇使用獨特技術的最高采礦産量項目的收入。差的項目，原來的金融打法，根據曆史回溯測試，年收入可以達到482%。我們將項目開源供大家使用（核心代碼保密），並通過專業第三方代碼審查機構的審查，確保所有用戶資金的安全。",
  "Enter amount": "輸入金額",
  Submit: "提交",
  "Clown fish  is only for new users, you can invite your friends to join to receive benefits.You will have a total of 1 pledge opportunity within 24 hours, invite friends to unlock more pledge amount":
    "小醜魚僅適用于新用戶，您可以邀請您的朋友加入以獲得好處。您將在24小時內共有1次質押機會，邀請朋友解鎖更多質押金額",
  Connect: "連接",
  Address: "地址",
  Level: "等級",
  Time: "時間",
  "Fish Pond Type": "魚池类型",
  Earn: "賺得",
  "Withdrawal Amount": "提现金額",
  Team: "團隊",
  Amount: "金額",
  Rate: "回報率",
  "Please enter the amount!": "請輸入金額！",
  "Pledge amount": "質押金額:",
  "Wallet calling...": "錢包調用中...",
  "Operation failed!": "操作失敗!",
  "Operation successful": "操作成功!",
  "Best DEFI vault": "FD",
  Copied: "已複制！",
  "Cannot copy!": "無法複制！",
  "Click to view": "點擊查看",
  Prompt: "提示",
  "Return Notice": "提現失敗的金額將在24小時回退到賬戶,請確保有足夠的gas費",
  "Referee Reward": "推广收益",
  "Withdrawal Income": "提取",
  "Earn Type": "收益類型",
  Status: "状态",
  "User Address": "当前用户",
  "Subordinate Address": "下级用户",
  "First Generation": "一代",
  "Second Generation": "二代",
  "Third Generation": "三代",
  "Not extracted": "未提取",
  Extracting: "提取中",
  "Extraction Complete": "提取完成",
  "Extraction Failed": "提取失败",
  "Draw Time": "时间",
  "Draw Hash": "HASH",
  OK: "確定",
  Cancel: "取消",
  Approve: "授權",
  "Available direct referral": "可用直推獎勵",
  "Draw direct referral rewards": "已領直推獎勵",
  "Available team rewards": "可用團隊獎勵",
  "Draw team rewards": "已領團隊獎勵",
  "Promotion income": "推薦收益",
  "Team income": "團隊收益",
  "User level": "用戶等級",
  "Team pledge amount": "團隊總質押",
  "Switch Chain": "切換鏈",
  "Switch Language": "切換語言",
  Home: "首頁",
  "Machine Gun Pool": "金融",
  My: "我的",
  "No data": "暂无数据",
  "The total pledge of the platform": "持幣總分紅",
  "Total address": "總地址",
  "Minimum profit ratio": "最低回報率",
  "FD related introduction": "FD相關介紹",
  "Scan the whole network": "掃描整個網絡",
  "Real-time data analysis to execute the best strategy":
    "實時監控鏈上數據分析，自動執行三明治，閃電貸，dex-cex等多種量化套利策略",
  "Strategy mix": "戰略組合",
  "Automatic switching of policies to ensure high yield":
    "富鼎基金除了在數字貨幣交易市場，藉由短中長期投資策略組合搭配高槓桿，由自動交易系統，創造高收益以外，另外與多個合作方，透過自身擁有的區塊鏈資源，進行代幣經濟投資模式，藉由數字貨幣的發行及雙幣投資操作，獲取高效益投資反饋。",
  "Financial freedom": "金融自由",
  "Deposit and withdraw anytime, flexible funds": "隨時存款和取款，靈活的資金",
  "Latest transaction": "最新交易",
  "To view": "查看",
  "Security audit": "安全審計",
  Partner: "合作夥伴",
  "Contact Customer Service": "聯系客戶服務",
  "The strongest DEFI machine gun pool": "量化、資管、DEFI",
  "FD owns the world's top ten funds' far-sighted financial plan and invites you to share financial returns!":
    "FD擁有全球十大基金的前瞻性財務計劃，並邀請您分享財務回報！",
  "Beginner's Guide": "新手指南",
  Welcome: "歡迎",
  "FD enjoy high yield": "FD享有高收益",
  Overview: "概覽",
  "Pledge Record": "質押記錄",
  "Current Reward": "當前獎勵",
  "Please link the wallet first": "請先鏈接錢包",
  "Invitation link": "邀請鏈接",
  "Total number of invites": "邀請總數",
  "Invitation total revenue": "邀請總收入",
  "Team Rewards": "團隊獎勵",
  "Harvest Reward": "收獲獎勵",
  "Level description": "級別描述",
  Introduction: "介紹",
  "Copy the link and share it with your friends, you will get higher pledge registration and enjoy higher investment income!":
    "複製鏈接並與您的朋友分享，您將獲得更高的質押注冊並享受更高的投資收益！",
  "Company Profile": "公司介紹",
  "We are a business entity of Fortune Group...":
    "我們是世界知名富達基金策略高層，在數位資產上擴大投資的基金公司。近年來，隨著數字貨幣在世界範圍內的普及，它已被廣泛接受並用於互聯網上的各種體育和虛擬商品交易。集團依托其在金融產品和國外淮淮地區的專業優勢，成立了富鼎公司。通過超高效、系統化的投資策略運作，創造有效、穩定的高年化收益。通過全球投資眾籌，可以擴大交易基礎，在資金穩定增長的情況下，可以為投資公眾和公司帶來雙贏局面。",
  "We have the world's top 30 investment strategy teams...":
    "我們擁有世界排名前30的投資策略團隊，在商品期貨、指數期貨和外彙領域享有高投資回報。特別是在過去兩年的數字資產投資戰略中，通過短期、中期和長期戰略規劃，平均年化率達到300%以上。",
  "FishDefi has a total of three chains: ETHTRXBSC...":
    "FishDefi共有三条链：ETHTRXBSC供您选择；10U质押仅适用于新用户，钱包中的余额必须有110U才能进行10U质押：此外，确保钱包中的每个箭头都有足够的渠道费（TRX）。您将有机会在24小时内激活您的帐户。24小时后，合同将自动将质押金额和利润退还给您的钱包账户。您可以邀请您的朋友加入，以获得更高级别，解锁更多承诺金额·",
  "After the first pledge is over...":
    "第壹次質押結束後：您將成為我們的VIP客戶：VIP客戶可以享受7*24小時的本金提取+回報：到達時間取決於防禦上的阻止情況：VIP客戶需要共享鏈接解鎖更多質押金額，每次您共享壹個朋友：您將增加500U的質押金額，您的日收入也將增加，如果您達到10人，您將獲得無限的質押金額：如果您共享的錢包沒有質押：系統將在識別後自動將您的VIP級別降低到相應的金額。",
  "Please refer to the table below：": "請參閱下表：",
  "Pledge quantity": "質押數量",
  "Profit % (days)": "回報率（%）",
  "Number of shared tokens": "分享Token数",
  Unlimited: "無限",
  "Refer to the event announcement": "請參閱活動公告",
  "Not fixed every week": "每週非固定",
  VIP11:
    "推薦一個有效錢包《直推下級質押100U為有效推廣》你的質押本金額度和收益會隨之提高，反之直推下級，提取本金後，上級質押額度和日收益百分比會下降。最高收益日化1.1%，質押額度隨著直推數量上升，每增加一個有效錢包地址，增加質押額度500U，以此類推，階梯式上升無上限",
  "Note: If the withdrawal on DAPP does...":
    "注意：如果DAPP上的提款未到達，請耐心等待，合同將在24小時後自動將本金和回報退還給您的錢包。在此期間，您將無法進行第二次質押：您必須等待硬幣返回錢包：您可以下次再次質押！",
  "Platform Introduction": "平台介紹",
  "Using Decentralized Finance (DEFI)...":
    "使用去中心化融資（DEFI），用戶資金通過智能合約聚合，獨特的FFT資金保護機製可以有效避免選擇使用獨特技術獲得最高准確收益的項目的收入。對於貧困項目，原始的拔出金融的方法，根據歷史回顧性測試，年收入可達到482%。我們將該項目的源代碼開放給所有人使用（核心代碼保密），並通過專業的第三方代碼審核人員，確保所有用戶資金的安全。",
  "Related question": "相關問題",
  "1： There must be a certain amount of gas in the wallet as a service charge on the chain when it is pledged. Different chains need to consume TRX, and NbN.":
    "一：錢包裏必須有一定的GAS作為質押時鏈上收取手續費。不同的鏈需要消耗TRX,和NBN。",
  "2： U on different chains has different links to DAPP. (you can apply for customer service or referrals to give you the link of the corresponding chain, otherwise your wallet will not be linked to DAPP software)":
    "二：不同鏈上的U，進入DAPP的鏈接不同。（可以申請客服或者推介人給你相應鏈的鏈接，否則你的錢包鏈接不上DAPP軟件）",
  '3： After entering DAPP, please pay attention to the bar box in the upper left corner of the software and confirm whether it has been linked. (if the data is not displayed, please click the word "link" to ensure that the link is successful and the platform data is clearly displayed.).':
    "三:進入DAPP後，請註意觀察軟件的左上角條框，請確認是否已經鏈接。（如果數據沒有顯示，請點擊鏈接字樣，確保已經鏈接成功，並清楚顯示平臺數據）。",
  "4： Click the machine gun pool option under the software to jump to the pledge interface. If you want to open pledge financing, please click authorization, and then enter the password, so that the authorization is successful.":
    "四：點擊軟件下方的金融選項，跳轉到質押界面，如要開啟質押理財，請點擊授權，然後輸入密碼，直致授權成功。",
  "5： Congratulations, you have become a VIP customer of Fuding wealth management, with an initial limit of 100u":
    "五：恭喜您，已經成為富鼎理財的VIP客戶，初始額度100U",
  "6： For the promotion of quota and grade, please consult your promoter or customer service! Thank you for your support!":
    "六：額度和等級的提升，請咨詢您的推介人或詢問客服！謝謝您的支持！",

  "intro-p1":"使用去中心化融資（DEFI），用戶資金通過智能合約聚合，獨特的FFT資金保護機製可以有效避免選擇使用獨特技術獲得最高准確收益的項目的收入。對於貧困項目，原始的金融的方法，根據歷史回顧性測試，年收入可達到482%。",
  "p1": "一:錢包裹必須有一定的GAS作為質押時鏈上收取手續費。不同的鏈需要消耗TRX和BNB還有ETH。",
  "p2": "二:不同鏈上的U，進入DAPP的鏈接不同。(可以申请客服或者推薦人给你相對應鏈的鏈接，否則你的錢包鏈接不上DAPP軟件)",
  "p3": "三:進入DAPP後，請注意觀察軟件的左上角條框，請確認是否已經鏈接。(如果數據沒有顯示，請點擊鏈接字樣，或者點擊右上角刷新頁面，確保已經鏈接成功，並清楚顯示平臺數據)。",
  "p4": "四:點擊軟件下方的（金融）選項，跳轉到質押界面，如要開啟質押理財，請點擊授權，然後輸入密碼，直至授權成功。",
  "p5": "五:恭喜您，已經成為富鼎理財的VIP客戶，初始額度100U",
  "p6": "六:額度和等級的提升，請諮詢您的推薦人或詢問客服!",
  "p7": "謝謝您的支持!",
  "Pledge range": "質押区間",
  "Direct Push Pledge": "直推質押",
  "Team pledge":  "團隊質押",
  "Recommendation reward": "推薦獎勵",
  "Number Of Direct Referral": "直推数量",
  "Number Of Indirect Referral": "间推数量",
  "Indirect Push Pledge": "间推質押",
  "Pledge Amount": "質押金額",
  "Exceeded the limit": "超出額度限製！",
  "Amount below minimum": "金額低於最小值！",
  "Subordinate": "下級",
  "Withdrawal reward": "取款獎勵",
  "1s": "秒提",
  "1-day interest": "7日定期",
  "3-day interest": "15日定期",
  "7-day interest": "30日定期",
  "60-day interest": "60日定期",
  "120-day interest": "120日定期",
  "General pledge": "活期質押",
  "Regular pledge": "定期質押",
  "Number of subordinates": "下級人数",
  "Pledged": "質押中",
  "Completed": "已完成",
  "Pledge Time": "質押時間",
  "Current pool pledge": "當前池質押",
  "Cannot operate": "无法操作!",
  "Cumulative reward": "累計獎勵",
  "Accumulated pledge": "累計質押",
  "Buy": "買",
  "Sell": "賣",
  "Mining": "挖礦",
  "Coming soon": "即將開放",
  "Latest Attack": "三明治套利",
  "Bind": "綁定",
  "Bind to superior": "綁定上級",
  "Binding successful": "綁定成功",
  "Please enter the address of the superior": "請輸入上級地址",
  "Team Total Pledge": "團隊總質押",

  "Team Reward": "團隊獎勵",
  "My Team": "我的團隊",
  "My Taxes": "我的稅收",
  "My Superior": "我的上級",
  "Announcement": "公告",
  "Customer Service": "客服",
  "Airdrop": "空投",
  "FDS Wallet": "FDS錢包",
  "Tax Details": "稅收明細",
  "FDS Details": "FDS明細",
  "Total Assets": "總資產",
  "Withdrawal": "提現",
  "Recharge": "充值",
  "*The data shows the last 7 days": "*數據顯示最近7天",
  "Daily income": "日收益",
  "Individual accumulated tax": "個人累計稅收",
  "Single cumulative income": "單人累計收益",
  "Tax rate": "稅率",
  "Tax exemption": "免稅額",
  "View Tax Statement": "查看稅單",
  "Records": "記錄",
  "Superior's address": "上級地址",
  "Superior grade": "上級等級",
  "Team acceleration": "團隊加速",
  "Friends invited": "好友邀請",
  "Online friends": "線上好友",
  "Current online friends": "當前線上好友",
  "Acceleration calculation formula": "加速計算公式",
  "Start Mining": "開始挖礦",
  "Activate once every 24 hours to continue to earn benefits": "每24小時激活一次，繼續賺取收益",
  "Base Speed": "基礎速率",
  "Team Speed": "團隊速率",
  "Real-time earnings": "實時收益",
  "Current total speed": "當前總速率",
  "Scan to add Twitter customer service, help you solve problems!": "掃碼添加Twitter客服，幫助您解決問題！",
  "Scan to add Telegram customer service, help you solve problems!": "掃碼添加Telegram客服，幫助您解決問題！",
  "Current Base Speed": "當前基礎速率",
  "The total issuance of FDS coins is 1 billion, and 750 million are all used for mining and production, and production will be reduced based on the number of FDS mined. When the FDS output reaches 300 million, the main currency listing plan will be launched!": "FDS幣總發行量為10億，其中7.5億全部用於挖礦和產出，並根據挖出的FDS數量進行減產。當FDS產量達到3億時，將啟動主幣上市計劃！",
  "Billion": "億",
  "Airdrop Record": "空投記錄",
  "Taxex": "稅收",
  "Extract": "提取",
  "Map": "映射",
  "National Airdrop": "全民空投",
  "VIP Airdrop": "VIP空投",
  "Online": "線上",
  "Draw Records": "提取記錄",
  "Not open yet": "暫未開放",
  "Mining income": "挖礦收益",
  "Taxes": "稅收",
  "Loading": "加載中",
  "Team Description": "團隊描述",
  "Team Level": "團隊等級", 
  "Recharge Amount": "充值金額",
  "Please enter the recharge amount": "請輸入充值金額",
  "Please enter the withdraw amount": "請輸入提現金額",
  "Please enter the USDT pledge amount": "請輸入USDT質押金額",
  "Approve FDS": "授權FDS",
  "Tip: Please check if you need enough fuel?": "提示：請檢查您是否需要足夠的燃油？",
  "Peer award": "平級獎",
  "Treasure": "宝藏",
  "Withdrawal record": "提現記錄",
  "My Reward": "我的獎勵",
  "Low Risk": "低風險",
  "High Income": "高收益",
  "State": "狀態",
  "OPEN": "打開",
  "Consumption once flipped": "翻牌消費次數",
  "Today's remaining flops": "今天剩余的翻牌次數",
  "Currently owns": "目前持有",
  "Flop record": "翻牌記錄",
  "Consume": "消費",
  "Win the lottery": "中獎",
  "Didn't win": "未中獎",
  "The usage limit has been reached today, Please participate again": "今天使用次數已達上限，請明天再来參與",
  "My FD": "我的FD",
  "Minimum withdrawal amount:": "最低提現金額:",
  "Received amount": "到賬金額",
  "Withdrawal service fee deducted:": "提現手續費扣除:",
  "Node": "節點",
  "Obtain": "獲得",
  "Withdrawing": "提現中",
  "Has arrived": "已到賬",
  "FDS account": "FDS賬戶",
  "FD account": "FD賬戶",
  "game-entry-1": "遊戲分爲FDS,FD,USDT三種模式，每種模式的玩法壹樣，只是支付的幣種不同，同時獎勵的幣種也不同",
  "game-entry-2": "用戶有壹定的概率獲得6-10倍之間獎勵的收益，收益及時到賬到余額可進行提現",
  "Flip Rules": "翻牌規則",
  "game-low-1": "每個用戶每天有10次機會,每次會消耗一定的FDS代幣",
  "game-low-2": "用戶每翻一次牌後都會進行重新洗牌",
  "game-low-3": "低風險模式有50%左右的中獎幾率,中獎金額也相對會少一點,適合風險厭惡型用戶參與",
  "game-high-1": "每個用戶每天有10次機會,每次會消耗一定的FDS代幣",
  "game-high-2": "用戶每翻一次牌後都會進行重新洗牌",
  "game-high-3": "高收益模式有20%左右的中獎幾率,中獎金額相對於低風險模式有所增加,比較適合風險喜好型的用戶參與",
  "Game":"遊戲",
  "Flip consumption": "翻牌消費",
  "FD Details": "FD明細",
  "FD Wallet": "FD錢包",
  "Approve FD": "授權FD",
  "Number of subscribers": "認購人數",
  "Phase 1": "第一期",
  "Phase 2": "第二期",
  "Phase 3": "第三期",
  "In Progress": "進行中",
  "Not Open": "未開放",
  "Starting block height": "開始区块",
  "End block height": "結束区块",
  "Subscription": "認購",
  "Subscription Price": "認購價格",
  "Subscription Amount": "認購金額",
  "Obtaining Tokens": "獲得代幣",
  "Node recruitment": "節點招募",
  "Node desc": "創世節點用戶是FD整個生態平臺核心的參與者和架構師,擁有共同參與平臺後期投票決策權,享受平臺所有的激勵和分潤,包括後期FD主網激勵,錢包利潤分紅等等",
  "Ecological Reward": "環境獎勵",
  "Start Time": "開始時間",
  "End Time": "結束時間",
  "Node Rights": "節點權利",
  "Total Award": "總獎勵",
  "Remaining Release": "剩餘釋放",
  "Withdrawable Amount": "可提現金額",
  "Obtain an FD token reward equivalent to 5000USDT (locked for 90 days, and then released monthly for 12 months to complete)": "獲取等值的FD代幣獎勵（鎖倉90天、之後逐月釋放12個月釋放完成）",
  "Recruitment Process": "招募进度",
  "Share 25% of exchange transaction fees": "分享交易所交易手續費的25%",
  "25% of the profit withdrawal fee on the sharing platform": "分享平臺回報提現手續費的25%",
  "Identity of platform co-founder": "平台創辦人身份",
  "Participate in the platform's later voting decision - making power": "參加平臺後期投票決策權",
  "Token Collection": "代幣收集",
  "Record": "記錄",
  "Date": "日期",
  "Quantity": "數量",
  "Participate": "參與",
  "Stay tuned": "敬請期待",
  "Redemption principal": "贖回本金", 
  "Gain profit": "賺取回報", 
  "Deduction fee": "扣除手續費",
  "Fee": "手續費",
  "Redemption": "贖回",
  "Pending approval": "待審核",
  "Pending transfer": "待轉賬",
  "Transferring": "轉賬中",
  "Successful": "成功",
  "Failed": "失败",
  "Ended": "已結束",
  "Subscription failed!": "認購失敗!",
  "Subscription successful!": "認購成功!",
  "Benefits can be received": "獲得獎勵",
  "No.": "期数",
  "Subscribed":  "已認購",
  "IDO Description": "IDO說明",
  "Token Release": "代幣釋放",
  "Extract Quantity": "提取數量",
  "The user successfully released 16% of the tokens after participating in IDO subscription, and the remaining 84% of the tokens were released at a monthly rate of 14% starting from token trading. The release was completed within 6 months": "用戶參與IDO認購成功釋放16%代幣,剩余84%的代幣從代幣開放交易開始每個月釋放14%額度,6個月釋放完畢",
  "Each address can only be subscribed once": "每個地址只能認購壹次",
  "The subscribed tokens are released on the token release page, and if there is a limit available, it can be withdrawn": "認購的代幣釋放在代幣釋放頁面,如有可領取額度即可提取",
  "Contact Official Application": "聯繫官方申請",
  "Quotes": "行情",
  "Service": "客服",
  "Platform": "平台",
  "Official site": "官方網站",
  "Swap": "交易",
  'Draw amount can not be 0': "提取金額不能為0",
  "Application": "应用",
  "Released": "已釋放",
  "Node users who have opened subscription permission": "開通認購權限的節點用戶三天內未認購完成合約會自動取消資格,且不能再認購",
  "Subscription requires maintaining an account of 10000u": "認購需要保持賬戶有10000u",
  "The node function will be closed when the node quota reaches": "節點名額達到或者認購周期完成將關閉節點功能",
  "Blind Box": "盲盒",
  "Lottery Results": "開獎結果",
  "Participation Record": "參與記錄",
  "USDT account": "USDT帳戶",
  "USDT Details": "USDT明細",
  "USDT Wallet": "USDT錢包",
  "Lucky Award": "幸運獎",
  'The first prize': "壹等獎",
  "Second prize": "二等獎",
  "Third prize": "三等獎",
  "Participation Award": "參與獎",
  "Random": "隨機",
  "Reward ranking": "獎勵排名",
  "Lottery rules": "抽獎規則",
  "Select a token": "選擇代幣",
  "Withdrawal service fee": "提現手續費",
  "Participation2": "參與記錄",
  "Current prize pool": "當前獎池",
  "If there are significant adverse conditions in the price before confirming the price, your transaction will be withdrawn": "如果在確認價格時有明顯的損益情況，您的交易將被撤回",
  "AMM: The difference between market prices and estimated prices is caused by the size of the transaction.": "AMM:交易對市價和估計價的差異是由交易大小引起的。",
  "Slippage Tolerance": "滑點上限",
  "Minimum acquisition amount": "最小獲得量",
  "Price impact": "價格影響",
  "Default Transaction Speed": "預設交易速度",
  "Price": "價格",
  "Set Up": "設置",
  "Recent Transactions": "最近交易",
  "Swap fee": "交易費用",
  "swap-help1": "買入收取獲得數量的3%的FD代幣,賣出收取售賣數量的5%FD代幣",
  "Balance": "餘額",
  "Maximum": "最大",
  "Minimum recharge amount:": "最低充值金額:",
  "Instant": "即時",
  "Standard": "標準",
  "Fast": "快速",
  "Default": "預設",
  "Mapping": "映射",
  "1. Equivalent FD of 1U market price participating in blind box once": "1. 市價1U的等值FD參與壹次盲盒",
  "2. 100% of the user's participation amount will be credited to the prize pool, with an initial bottom pool of 1WFD": "2.  用戶參與金額100%打入獎池,初始底池為10000U等值的FD数量.",
  "3. The daily first prize amount accounts for 10% of the total prize pool, the second prize amount accounts for 8% of the total prize pool, equivalent to FD, the third prize amount accounts for 5% of the total prize pool, and the number of awards is 1. Once the awards are withdrawn on that day, they will not be issued again, and the award amount will be reset at midnight every day": "3. 每日壹等獎金額占獎池總獎金的5%,二等獎占總獎池的3%等值USDT,三等獎占總獎池的2%,獎項數量為1,每日必定开出三个奖项，獎項抽出即不會再開出,每日零點重置獎項金額.",
  "4. The Lucky Award has an indefinite daily quantity and an indefinite bonus of 300FD, with a single cap": "4. 幸運獎奖金为fd,每日不固定數量,不固定獎金.",
  "5. Participating users will win 100% of the prize and be synchronized on the announcement board of the prize pool": "5. 參與用戶100%中獎,同步在獎池公告欄",
  "Treasure Rewards": "寶藏獎勵",
  "Exchange": "兌換",
  "Approve Failed": "授權失敗",
  "Approve Success": "授權成功",
  "Transaction": "交易",
  "No available balance": "餘額不足",
  "Confirm Swap": "確認交易",
  "To Be Mapped": "待映射",
  "Mapped Quantity": "轉入映射池",
  "All": "全部",
  "Total Return": "總收益",
  "To Be Released": "待釋放",
  "Mapping record": "映射記錄",
  "Release record": "釋放記錄",
  "Income": "收益",
  "Sure": "確定",
  "Swap2": "購買",
  "Freed": "釋放",
  "Output is estimated. You will receive at least {coin} or the transaction will revert.": "輸出價格为估計,您將收到至少{coin}或交易將被取消。",
  "24H": "24時",
  "1W": "1周",
  "1M": "1月",
  "1Y": "1年",
  "Transfer out": "轉出",
  "Transfer Amount": "轉出金額",
  "FDS mapping": "FDS映射",
  "FDS mapping transfer": "FDS映射轉移",
  "Mapping team rewards": "映射團隊獎勵",
  "Node dividend": "節點分紅",
  "Coin holding dividends": "持幣分紅",
  "Team dividend": "團隊分紅",
  "Transfer to airdrop": "空投轉入",
  "FD mapping rewards": "FD映射獎勵",
  "FD mapping release": "FD映射釋放",
  "No available balance!": "餘額不足!",
  "Please customize slippage!": "請調整滑點!",
  "Please input amount!": "請輸入金額!",
  "Below the minimum withdrawal amount!": "低於最低提現金額!",
  "Insufficient balance": "餘額不足",
  "Bonus": "獎金",
  "Confirm": "確認",
  "Frequency": "頻次",
  "Congratulations on winning": "恭喜您獲得",
  "Blind box consumption": "盲盒消耗",
  "Blind box reward": "盲盒獎勵",
  "Blind box dividend": "盲盒分紅",
  "time": "次",
  "The maximum value has been reached": "已達到最大值",
  "ABonus": "分紅榜",
  "USDT Treasure": "USDT寶藏",
  "FDS Treasure": "FDS寶藏",
  "FD Treasure": "FD寶藏",
  "game-page-rule-1": "每個用戶每天有10次機會，每次會消耗固定數量的代幣",
  "game-page-rule-2": "用戶每翻壹次牌後都會進行重新洗牌",
  "game-page-rule-3": "翻中的用戶能獲得6-10倍對應的獎勵",
};