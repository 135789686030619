<template>
  <router-view></router-view>
  <van-popup v-model:show="showInvite" round>
    <div class="bind-inviteCode">
      <!-- <span class="close-bind" @click="() => $store.commit('setInvite', false)">
          <van-icon name="cross" />
        </span> -->
      <h4>{{ $t('Bind to superior') }}</h4>
      <van-field v-model="inviteCode" clearable :placeholder="$t('Please enter the address of the superior')"
        @click-icon="inviteCode = ''" />
      <van-button type="primary" round block class="mt-10" @click="submit">
        {{ $t('Bind') }}
      </van-button>
    </div>
  </van-popup>
</template>

<script>
import { Notify, Field } from 'vant';
import { bindCode } from '@/api/api';

const getQueryString = function(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

export default {
  name: "App",
  components: {
    [Field.name]: Field
  },
  data() {
    return {
      inviteCode: getQueryString('inviteCode') || ''
    }
  },
  computed: {
    showInvite() {
      return this.$store.state.showInvite;
    },
  },
  mounted() {
    //document.addEventListener('visibilitychange', this.handleWindowVisible);
    this.$store.dispatch('initWeb3');
  },
  unmounted() {
    //document.removeEventListener('visibilitychange', this.handleWindowVisible);
  },
  methods: {
    // handleWindowVisible() {
    //   if (document.hidden === false) {
    //     this.$store.dispatch('initWeb3');
    //   }
    // },
    submit() {
      if (this.inviteCode === '') {
        return Notify(this.$t('Please enter the address of the superior'))
      }
      bindCode(this.inviteCode).then(res => {
        console.log(res)
        Notify({ type: 'success', message: this.$t('Binding successful') })
        this.$store.commit('setInvite', false)
      })
    }
  }
};
</script>

<style>
:root {
  --background-color: #F3F9FE;
  --van-nav-bar-text-color: #333333;
  --van-nav-bar-title-text-color: #333333;
  --van-nav-bar-height: 44px;
  --van-nav-bar-background-color: #fff;
  --van-nav-bar-icon-color: #238BE8;
  --van-button-primary-border-color: #238BE8;
  --van-button-primary-background-color: #238BE8;
}

a {
  color: #238BE8;
}

html,
body {
  height: 100%;
  background-color: var(--background-color);
}

#app {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

.bind-inviteCode {
  position: relative;
  width: 70vw;
  padding: 30px;
}

.bind-inviteCode .close-bind {
  position: absolute;
  right: 15px;
  top: 15px
}

.bind-inviteCode h4 {
  margin-top: 0;
}

.van-nav-bar {
  box-shadow: 0px 0.5px 0px rgba(255, 255, 255, 0.05);
}

.box {
  padding: 15px;
  background: #fff;
  border-radius: 14px;
}

.f12 {
  font-size: 12px;
}

.f14 {
  font-size: 14px;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-lowercase {
  text-transform: lowercase
}

.text-uppercase {
  text-transform: uppercase
}

.text-capitalize {
  text-transform: capitalize
}

.text-underline {
  text-decoration: underline
}

.text-center {
  text-align: center;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}
.mb-20{
    margin-bottom: 20px;
}
</style>
