import axios from 'axios';

import { Notify } from 'vant';

export function post(url, data = {}, params = {}) {
    let baseUrl = window._api_url;
    const options = {
        headers: {},
        ...params
    }
    const token = localStorage.getItem('token');
    if(token && url !== '/uUser/auth') {
        options.headers.token = token;
    }
    if (url.indexOf('userAccount/') > -1 || url.indexOf('swap/') > -1 || url.indexOf('userMine/') > -1 || url.indexOf('rank/') > -1 || url.indexOf('lLuckyBase/') > -1 || url.indexOf('mapping/') > -1 || url.indexOf('box/') > -1) {
        baseUrl = window._api_url2;
    }
    options.headers.lang = window.localStorage.getItem('PoolLang') || "en";
    return new Promise((resovle, reject) => {
        axios({
            method: 'post',
            url: baseUrl + url,
            data,
            ...options
        }).then(res => {
            if(res.data.code === 200) {
                resovle(res.data);
            }else{
                if (res.data.code === 401) {
                    const lang = window.localStorage.getItem('PoolLang') || "en";
                    if (lang === 'en') {
                        Notify('Unauthorized!');
                    } else if (lang === 'zh') {
                        Notify('未授權！');
                    } else if (lang === 'hy') {
                        Notify('승인되지 않음!');
                    } else if (lang === 'yn') {
                        Notify('Không được phép!');
                    }
                } else {
                    Notify(res.data.msg);
                }
                reject(res.data);
            }
        }).catch(e => {
            Notify(e);
            reject(e);
        })
    })
}

export function get(url, data = {}, params = {}) {
    let baseUrl = window._api_url;
    const options = {
        headers: {},
        ...params
    }
    const token = localStorage.getItem('token');
    if(token && (url !== '/uUser/auth' && url.indexOf('userBase/') === -1)) {
        options.headers.token = token;
    }
    if (url.indexOf('userAccount/') > -1 || url.indexOf('swap/') > -1 || url.indexOf('userMine/') > -1 || url.indexOf('rank/') > -1 || url.indexOf('lLuckyBase/') > -1 || url.indexOf('mapping/') > -1 || url.indexOf('box/') > -1) {
        baseUrl = window._api_url2;
    }
    options.headers.lang = window.localStorage.getItem('PoolLang') || "en";
    return new Promise((resovle, reject) => {
        axios({
            method: 'get',
            url: baseUrl + url,
            data,
            ...options
        }).then(res => {
            if(res.data.code === 200) {
                resovle(res.data);
            }else{
                if (res.data.code === 401) {
                    const lang = window.localStorage.getItem('PoolLang') || "en";
                    if (lang === 'en') {
                        Notify('Unauthorized!');
                    } else if (lang === 'zh') {
                        Notify('未授權！');
                    } else if (lang === 'hy') {
                        Notify('승인되지 않음!');
                    } else if (lang === 'yn') {
                        Notify('Không được phép!');
                    }
                } else {
                    Notify(res.data.msg);
                }
                reject(res.data);
            }
        }).catch(e => {
            Notify(e);
            reject(e);
        })
    })
}